import * as styles from '../../../styles/blog.module.css';

import { Link, graphql, useStaticQuery } from 'gatsby';
import { getBlogPostTags, getUrl } from '../../../util';

import BlockRow from '../../../components/UI/BlockRow/BlockRow';
import BlogCard from '../../../components/Card/BlogCard';
import CardGrid from '../../../containers/CardGrid/CardGrid';
import DefaultImage from '../../../images/Default-Preview.png';
import Layout from '../../../containers/layout';
import MarkdownIt from 'markdown-it';
import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import slugify from 'slugify';

const Blog = (props) => {
  const data = useStaticQuery(graphql`
    query blogPostsQuery {
      allStrapiBlogs(limit: 9, sort: { order: DESC, fields: Date }) {
        nodes {
          id
          Title
          Tags
          Content
          Author
          SEO {
            Slug
            Keywords
          }
          CoverImage {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          Date(formatString: "DD MMMM, YYYY")
        }
      }
      strapiBlogs {
        id
        Title
        Tags
        Content
        SEO {
          Slug
          Keywords
          PreviewImage {
            url
            width
            height
            formats {
              small {
                url
                width
                name
                height
              }
            }
          }
        }
        CoverImage {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        Date(formatString: "DD MMMM, YYYY")
      }
      allSitePage {
        nodes {
          path
          context {
            id
          }
        }
      }
      strapiStaticPages(Title: { eq: "Blog" }) {
        Title
        SEO {
          MetaTitle
          MetaDescription
          Keywords
          PreviewImage {
            formats {
              small {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `);

  const seoData = data.strapiStaticPages.SEO;

  const blogPostsData = data.allStrapiBlogs.nodes.slice(1);
  const urlArr = data.allSitePage.nodes;

  const previewBlog = data.allStrapiBlogs.nodes[0];

  //redo
  const md = new MarkdownIt({ html: true });

  const previewContent = md
    .render(previewBlog.Content)
    .split('</p>')[0]
    .replace(/(<([^>]+)>)/gi, '');

  const blogPosts = blogPostsData.map((post, i) => {
    return (
      <BlogCard
        title={post.Title}
        author={post.Author}
        date={post.Date}
        img={
          post.CoverImage ? getImage(post.CoverImage.localFile) : DefaultImage
        }
        link={getUrl(urlArr, post.id)}
        tags={getBlogPostTags(post)}
        key={i}
      />
    );
  });

  return (
    <Layout seoData={seoData} location={props.location}>
      <h1>News & Events</h1>
      <BlockRow>
        <div className={styles.Image}>
          <img
            src={
              previewBlog.CoverImage ? previewBlog.CoverImage.url : DefaultImage
            }
            alt='blog'
            className={styles.Image}
          />
        </div>
        <div className={styles.Content}>
          <Link to={getUrl(urlArr, previewBlog.id)}>
            <h2>{previewBlog.Title}</h2>
          </Link>

          <Link to={`/about/team/${slugify(previewBlog.Author, { lower: true })}`}>
            <p>
              <strong>{previewBlog.Author}</strong>
            </p>
          </Link>

          <p className={styles.Date}>{previewBlog.Date}</p>
          <p>
            {previewContent}
            <strong>
              <Link to={getUrl(urlArr, previewBlog.id)}> Read more...</Link>
            </strong>
          </p>
        </div>
      </BlockRow>
      <h3>RECENT</h3>
      <CardGrid show>{blogPosts}</CardGrid>
      <div className={styles.BlogLink}>
        <Link to='./posts'>
          <p>See more blog posts</p>
        </Link>
      </div>
    </Layout>
  );
};

export default Blog;
